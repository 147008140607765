import React from 'react'

import logoSrc from '../assets/logo.jpg'

import './sa-water-logo.css'

const SaWaterLogo = () => {
  return <img className="topLeft" src={logoSrc} alt="Logo" />
}

export default SaWaterLogo
