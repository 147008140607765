import React, { FunctionComponent, useState } from 'react'

interface IMapTypeSelectProps {
  mapTypeId: string
  onSelectedMapTypeChanged: (type: string) => void
}

const MapTypeSelect: FunctionComponent<IMapTypeSelectProps> = ({
  mapTypeId,
  onSelectedMapTypeChanged,
}) => {
  const [selectedMapType, setSelectedMapType] = useState(mapTypeId)

  const mapTypes = ['satellite', 'hybrid', 'roadmap']

  const selectMapType = type => {
    setSelectedMapType(type)
    onSelectedMapTypeChanged(type)
  }

  const mapTypeOptionButtons = mapTypes.map(type => {
    return (
      <button
        type="button"
        className={`gm-control-active map-type-select ${
          selectedMapType === type ? 'selected' : ''
        }`}
        key={`map-type-select-${type}`}
        onClick={() => selectMapType(type)}
      >
        {type}
      </button>
    )
  })

  return <div className="flex-container">{mapTypeOptionButtons}</div>
}

export default MapTypeSelect
