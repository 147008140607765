import { Circle } from '@react-google-maps/api'
import React, { useEffect, useRef, useState } from 'react'

import { airTempColorScale } from '../const/colorScales'
import { IMapPlace, IMapPlaceSensor } from '../types/mapPlace'

interface IMapCircleProps {
  site: IMapPlace
  showInfoWindow: (site: IMapPlace) => void
  minAirTemp: number
  airTempColorInterval: number
  airTempReadingInterval: number
  zoomLevel: number
}

const MapCircle = ({
  site,
  showInfoWindow,
  minAirTemp,
  airTempColorInterval,
  airTempReadingInterval,
  zoomLevel,
}) => {
  const position = {
    lat: site.siteLocation.latitude,
    lng: site.siteLocation.longitude,
  }

  const [isLoading, setIsLoading] = useState(true)
  const [drawColor, setDrawColor] = useState('')
  const isMounted = useRef(true)

  const onCircleClick = () => {
    showInfoWindow(site)
  }

  const calculateColorScale = (): void => {
    const [temperatureSensor] = site.sensors.filter((sensor: IMapPlaceSensor) =>
      sensor.type.includes('Temperature'),
    )
    const tempValue = parseInt(temperatureSensor.value, 10)

    const airTempRatio = (tempValue - minAirTemp) * airTempReadingInterval
    const colorLevel = Math.floor(airTempRatio / airTempColorInterval) // select the color level

    setDrawColor(airTempColorScale[colorLevel])
  }

  useEffect(() => {
    try {
      calculateColorScale()
    } finally {
      setIsLoading(false)
    }
    return () => {
      isMounted.current = false
    }
  }, []) // we only want to calculate once here, so use effect callback here

  // TODO
  const drawOptions = {
    strokeOpacity: 0.0,
    fillColor: drawColor,
    fillOpacity: 0.65,
  }

  const renderCircle = () => {
    const scaleFactor = (18 / zoomLevel) * 1.15

    return (
      <Circle
        options={drawOptions}
        center={position}
        radius={20 ** scaleFactor}
        onClick={onCircleClick}
      />
    )
  }

  return isLoading ? null : renderCircle()
}

export default MapCircle
