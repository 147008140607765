import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api'
import React, { useEffect, useState } from 'react'

import MarkerIcon from '../assets/marker.png'
import config from '../config'
import { airTempColorScale } from '../const/colorScales'
import googleMapLibraries from '../const/googleMapLibraries'
import mapOptions from '../const/mapOptions'
import { IMapPlace } from '../types/mapPlace'

import PinIcon from './icons/pin'
import './map.scss'
import MapCircle from './mapCircle'
import MapInfoWindow from './mapInfoWindow'
import MapMarker from './mapMarker'
import MapSearchBox from './mapSearchBox'
import MapTypeSelect from './mapTypeSelect'

interface IMapProps {
  minAirTemp: number
  maxAirTemp: number
  sites: IMapPlace[]
  haveError: boolean
}

const MapComponent = ({ sites, minAirTemp, maxAirTemp, haveError }) => {
  const [mapRef, setMapRef] = useState()
  const [center, setCenter] = useState({ lat: -34.9388083, lng: 138.583825 })
  const [zoom, setZoom] = useState(12)
  const [bounds, setBounds] = useState()
  const [mapTypeId, setMapTypeId] = useState('satellite')
  const [infoWindow, setInfoWindow] = useState()
  const [showMyLocation, setShowMyLocation] = useState(false)
  const [userLocation, setUserLocation] = useState({ lat: null, lng: null })
  const [userSelectedPlace, setUserSelectedPlace] = useState(null) // this to show the image of location potentially
  const [userSelectedLocation, setUserSelectedLocation] = useState({
    lat: 0,
    lng: 0,
  })

  // Load the Google maps scripts
  const { isLoaded } = useLoadScript({
    // Enter your own Google Maps API key
    googleMapsApiKey: config.googleMapApiKey,
    libraries: googleMapLibraries,
  })

  // Iterate myPlaces airTempColorScaleto size, center, and zoom map to contain all markers
  const fitBounds = googleMap => {
    const newBounds = new (window as any).google.maps.LatLngBounds()

    sites.forEach((site: IMapPlace) => {
      newBounds.extend(
        new (window as any).google.maps.LatLng(
          site.siteLocation.latitude,
          site.siteLocation.longitude,
        ),
      )
    })

    // set all area as bounds so user can search location inside available area
    // it would be pass to search box
    setBounds(newBounds)
    googleMap.fitBounds(newBounds)
  }

  const loadHandler = googleMap => {
    // Store a reference to the google map instance in state
    setMapRef(googleMap)

    // if there is error no need to fit bounds
    if (!haveError && sites.length > 0) {
      // Fit googleMap bounds to contaisetCentern all markers
      fitBounds(googleMap)
    }
  }

  const onMapClicked = () => {
    // if any window opened
    if (mapRef && infoWindow) {
      setInfoWindow(null)
    }
  }

  const onMapZoomChanged = () => {
    if (mapRef) {
      setZoom(mapRef.getZoom())
    }
  }

  const onMapTypeChanged = () => {
    if (mapRef) {
      setMapTypeId(mapRef.getMapTypeId())
    }
  }

  const onSelectedMapTypeChanged = (type: string) => {
    setMapTypeId(type)
  }

  const getUserPosition = (cb): any => {
    navigator.geolocation.getCurrentPosition(position => {
      cb({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      })
    })
  }

  const clearUserLocation = () => {
    setShowMyLocation(false)
    setUserLocation({ lat: null, lng: null })
  }

  const onShowMyLocationChanged = () => {
    setShowMyLocation(true)
  }

  const onUserSelectedPlace = (place: any) => {
    if (place === null) {
      setUserSelectedLocation({ lat: 0, lng: 0 })
    } else {
      setUserSelectedLocation({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      })
    }
    setUserSelectedPlace(place)
  }

  // side effect of showing location
  useEffect(() => {
    if (showMyLocation) {
      getUserPosition(position => {
        setCenter({ lat: position.latitude, lng: position.longitude })
        setUserLocation({ lat: position.latitude, lng: position.longitude })
        setZoom(15)
      })
    }
  }, [showMyLocation])
  useEffect(() => {
    if (userSelectedLocation.lat !== 0 && userSelectedLocation.lng !== 0) {
      setCenter({
        lat: userSelectedLocation.lat,
        lng: userSelectedLocation.lng,
      })
      setZoom(15)
    }
  }, [userSelectedLocation])

  // take site id
  const showInfoWindow = (site: IMapPlace) => {
    let mapInfoWindow

    // TODO: if site exist?
    if (site) {
      mapInfoWindow = (
        <MapInfoWindow key={`info_window_${site.id}`} site={site} />
      )
    }

    setInfoWindow(mapInfoWindow)
  }

  // onMapCenterChanged determine the color scale here
  const airTempColorInterval = 100 / airTempColorScale.length
  const airTempReadingInterval = 100 / (maxAirTemp - minAirTemp)

  const siteCircles = sites.map((site: IMapPlace) => {
    return (
      <MapCircle
        key={`map_circle_${site.id}`}
        site={site}
        showInfoWindow={showInfoWindow}
        minAirTemp={minAirTemp}
        airTempColorInterval={airTempColorInterval}
        airTempReadingInterval={airTempReadingInterval}
        zoomLevel={zoom}
      />
    )
  })

  const siteMarkers = sites.map((site: IMapPlace) => {
    return (
      <MapMarker
        key={`marker_${site.id}`}
        zoomLevel={zoom}
        site={site}
        showInfoWindow={showInfoWindow}
        mapTypeId={mapTypeId}
      />
    )
  })

  const userLocationIcon = {
    url: MarkerIcon,
  }

  const userLocationMarker = (
    <Marker
      key="user-location-marker"
      position={userLocation}
      icon={userLocationIcon}
    />
  )

  const userSelectedLocationMarker = (
    <Marker
      key="user-selected-location-marker"
      position={userSelectedLocation}
    />
  )

  const topControlSection = (
    <div id="center-top-controls" className="flex-container">
      {isLoaded && bounds ? (
        <div className="flex-item">
          <MapSearchBox
            onUserSelectedPlace={onUserSelectedPlace}
            bounds={bounds} // pass the bounds into the search box so the result will only bias on the available bounds
          />
        </div>
      ) : null}
      <MapTypeSelect
        mapTypeId={mapTypeId}
        onSelectedMapTypeChanged={onSelectedMapTypeChanged}
      />
    </div>
  )

  const showMyLocationButton = showMyLocation ? (
    <button
      type="button"
      className="gm-control-active control-icon-wrapper control-icon-selected"
      onClick={clearUserLocation}
    >
      <div className="svg-icon">
        <PinIcon />
      </div>
    </button>
  ) : (
    <button
      type="button"
      className="gm-control-active control-icon-wrapper"
      onClick={onShowMyLocationChanged}
    >
      <div className="svg-icon">
        <PinIcon />
      </div>
    </button>
  )

  const map = (
    <GoogleMap
      // Do stuff on map initial laod
      onLoad={loadHandler}
      center={center}
      zoom={zoom}
      options={mapOptions}
      mapTypeId={mapTypeId}
      mapContainerStyle={{
        height: '100%',
        width: '100%',
      }}
      onZoomChanged={onMapZoomChanged}
      onClick={onMapClicked}
      onMapTypeIdChanged={onMapTypeChanged}
    >
      {siteCircles}
      {/* hide the temperature marker and only show them if zoom in */}
      {zoom > 11 ? siteMarkers : null}
      {/* User location */}
      {userLocation.lat && userLocation.lng ? userLocationMarker : null}
      {/* Uer search location */}
      {userSelectedLocation.lat && userSelectedLocation.lng
        ? userSelectedLocationMarker
        : null}
      {/* Site information pop up */}
      {infoWindow}
      {/* control section */}
      <div id="left-bottom-controls">{showMyLocationButton}</div>
      {topControlSection}
    </GoogleMap>
  )

  return isLoaded ? map : null
}

export default MapComponent
