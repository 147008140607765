import 'normalize.css'
import * as React from 'react'
import 'react-app-polyfill/ie11'
import * as ReactDOM from 'react-dom'

import Main from './main'
import * as serviceWorker from './serviceWorker'
import { startMirage } from './utils/mirage'

// start mirage in development env only

if (
  process.env.REACT_APP_STAGE === 'dev' ||
  process.env.REACT_APP_STAGE === 'test'
) {
  console.log('staring with mirage mock server..')
  startMirage({ environment: process.env.NODE_ENV })
}

ReactDOM.render(<Main />, document.getElementById('app'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register({})
