const config = {
  dev: {
    apiEndpoint: '',
    googleMapApiKey: 'AIzaSyD3VXkRIRxzTQT1C64YMxkUg0_qllXiCWg',
  },
  stage: {
    apiEndpoint: 'https://api.staging.sawatermap.fleet-infra.net',
    googleMapApiKey: 'AIzaSyD3VXkRIRxzTQT1C64YMxkUg0_qllXiCWg',
  },
  prod: {
    apiEndpoint: 'https://api.sawatermap.fleet-infra.net',
    googleMapApiKey: 'AIzaSyDw7t1LQLqGB9Jvw99xv3wPkuMbWloUJ_w',
  },
  common: {
    apiEndpoint: '',
    googleMapApiKey: 'AIzaSyD3VXkRIRxzTQT1C64YMxkUg0_qllXiCWg',
  },
}

const NODE_ENV = process.env.REACT_APP_STAGE || 'dev'

const runtimeConfig = { ...config.common, ...config[NODE_ENV] }

if (process.env.REACT_APP_API_URL) {
  console.log('Api endpoint overridden to ', process.env.REACT_APP_API_URL)
  runtimeConfig.apiEndpoint = process.env.REACT_APP_API_URL
}

export default runtimeConfig
