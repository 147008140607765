import * as React from 'react'

import MapPage from './pages/map'
import './styles/app.scss'

// Create an intersection type of the component props and our Redux props.
const Main: React.FC<{}> = () => {
  return <MapPage />
}

// Normally you wouldn't need any generics here (since types infer from the passed functions).
// But since we pass some props from the `index.js` file, we have to include them.
// For an example of a `connect` function without generics, see `./containers/LayoutContainer`.
export default Main
