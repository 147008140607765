import React, { useEffect, useRef, useState } from 'react'

import fetchSites from '../api/fetchSites'
import FleetLogo from '../components/fleet-logo'
import LoadingSpinner from '../components/loadingSpinner'
import Map from '../components/map'
import SaWaterLogo from '../components/sa-water-logo'
import { IMapPlace, IMapPlaceSensor } from '../types/mapPlace'

const MapPage = () => {
  // -------- effects
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)
  const isMounted = useRef(true)
  // refetch timer
  const refetchInterval = 600
  const [timeLeft, setTimeLeft] = useState(refetchInterval)

  // -------- Data
  const [sites, setSites] = useState([])

  const callFetchSites = async () => {
    return fetchSites()
  }

  const callApi = async (backgroundLoading = false) => {
    if (!backgroundLoading) {
      setIsLoading(true)
    }

    const response = await callFetchSites()

    if (isMounted.current) {
      try {
        const json = await response.json()
        if (response.ok) {
          const sitesResult = json

          setSites(sitesResult)
        } else {
          setError(true)
        }
      } finally {
        setIsLoading(false)
      }
    }
  }

  useEffect(() => {
    if (!timeLeft) {
      callApi(true)
      setTimeLeft(refetchInterval)
    }

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 5)
    }, 5000)

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId)
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft])

  useEffect(() => {
    callApi()

    return () => {
      isMounted.current = false
    }
  }, [])

  // flat sensors map
  const allAirTempSensors = sites
    .map((site: IMapPlace) => {
      return site.sensors
    })
    .reduce((a, b) => {
      return a.concat(b)
    }, [])

  const sensorsMap = () =>
    allAirTempSensors
      .filter((sensor: IMapPlaceSensor) => sensor.type.includes('Temperature'))
      .map((sensor: IMapPlaceSensor) =>
        parseInt(sensor.value.replace(/"/g, ''), 10),
      )

  let [minAirTemp, maxAirTemp] = [
    Math.min.apply(null, sensorsMap()) - 0.1,
    Math.max.apply(null, sensorsMap()) + 0.1,
  ]

  // override the min max range is too close with each other
  // let say by default min is 5 and max is 55 <- shall fit for ADL weather
  if (maxAirTemp - minAirTemp <= 5) {
    minAirTemp -= 10
    maxAirTemp += 10
  } else if (maxAirTemp - minAirTemp <= 10) {
    minAirTemp -= 5
    maxAirTemp += 5
  }

  // if the max air temp is less than 20
  // bump it up and show all dots as blue
  if (maxAirTemp <= 20) {
    maxAirTemp += 10
  }

  const mapElement = (
    <div
      className="fullscreen"
      style={{ display: `${isLoading ? 'none' : 'block'}` }}
    >
      <Map
        sites={sites}
        minAirTemp={minAirTemp}
        maxAirTemp={maxAirTemp}
        haveError={error}
      />
    </div>
  )
  return (
    <div className="fullscreen">
      <SaWaterLogo />
      <FleetLogo />
      {/* loading */}
      {isLoading && <LoadingSpinner />}
      {/* loaded */}
      {!isLoading && mapElement}
    </div>
  )
}

export default MapPage
