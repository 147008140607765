import { Marker } from '@react-google-maps/api'
import React, { FunctionComponent } from 'react'

// types
import { IMapPlace, IMapPlaceSensor } from '../types/mapPlace'

interface IMapMarkerProps {
  zoomLevel: number
  mapTypeId: string
  site: IMapPlace
  showInfoWindow: (site: IMapPlace) => void
}

const MapMarker: FunctionComponent<IMapMarkerProps> = ({
  site,
  showInfoWindow,
  zoomLevel,
  mapTypeId,
}) => {
  const position = {
    lat: site.siteLocation.latitude,
    lng: site.siteLocation.longitude,
  }

  const onMarkerClick = () => {
    // potentially trigger other function
    showInfoWindow(site)
  }

  const [airTempSensor] = site.sensors.filter((sensor: IMapPlaceSensor) =>
    sensor.type.includes('Temperature'),
  )

  const temperature = parseInt(airTempSensor.value, 10)

  // font style change when map type changed (default white for satellite view)
  const fontStyle = {
    color: '#ffffff',
  }
  if (mapTypeId === 'roadmap') fontStyle.color = '#000000'

  const siteLabel = () => {
    // with scale
    if (zoomLevel >= 13) {
      return {
        text: `${temperature} °C`,
        fontSize: `${zoomLevel * 1.1}px`,
        fontWeight: '800',
        ...fontStyle,
      }
    }
    if (zoomLevel <= 15 && zoomLevel >= 14) {
      return {
        text: `${temperature} °C`,
        fontSize: `${zoomLevel}px`,
        fontWeight: '700',
        ...fontStyle,
      }
    }
    if (zoomLevel <= 16) {
      return {
        text: `${temperature} °C`,
        fontSize: `${zoomLevel * 0.9}px`,
        fontWeight: '500',
        ...fontStyle,
      }
    }
    return null
  }

  const renderMarker = () => {
    return (
      <Marker
        key={`marker_${site.id}`}
        position={position}
        onClick={onMarkerClick}
        icon="null"
        label={siteLabel()}
      />
    )
  }

  return renderMarker()
}

export default MapMarker
