import React from 'react'

import logoSrc from '../assets/fleet-logo.png'

import './fleet-logo.css'

const FleetLogo = () => {
  return (
    <div className="bottomMiddle flex-container">
      <a
        id="fleet-logo-link"
        className="flex-container"
        href="https://fleet.space"
      >
        <span id="powered-by">Powered By</span>
        &nbsp;
        <img id="fleet-logo" src={logoSrc} alt="Logo" />
      </a>
    </div>
  )
}

export default FleetLogo
