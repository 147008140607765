import React from 'react'

export default () => {
  return (
    <svg
      id="bbq-icon"
      height="40"
      viewBox="0 0 44 44"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(7.000000, 9.000000)"
          fill="#000000"
          fillRule="nonzero"
        >
          <path
            className="svg-block"
            d="M30,10 L28,10 L28,9.5 C28,9.22387694 27.7761231,9 27.5,9 L2.5,9 C2.22387694,9 2,9.22387694 2,9.5 L2,10 L0,10 L0,11 L2,11 L2,11.5 C2,11.6081543 2.03515625,11.7133789 2.10009762,11.8000488 L5,15.6665039 L5,26.5 C5,26.7761231 5.22387694,27 5.5,27 L7.5,27 C7.77612306,27 8,26.7761231 8,26.5 L8,23.5 L22,23.5 L22,26.5 C22,26.7761231 22.2238769,27 22.5,27 L24.5,27 C24.7761231,27 25,26.7761231 25,26.5 L25,15.6665039 L27.8999024,11.8000488 C27.9648437,11.7133789 28,11.6081543 28,11.5 L28,11 L30,11 L30,10 Z M27,10 L27,11 L3,11 L3,10 L27,10 Z M5.75,15 L3.5,12 L26.5,12 L24.25,15 L5.75,15 Z M6,26 L6,16 L7,16 L7,26 L6,26 Z M8,22.5 L8,16 L22,16 L22,22.5 L8,22.5 Z M23,26 L23,16 L24,16 L24,26 L23,26 Z"
          />
          <path
            className="svg-block"
            d="M14.0002601,6 L15.1626229,6 C15.1566635,5.73925781 15.2670539,5.48632812 15.4713755,5.293457 C16.1754337,4.53857425 16.1754337,3.4643555 15.4713755,2.70947262 C15.0590432,2.30371094 15.0590432,1.69824219 15.4713755,1.2924805 C15.8201411,0.92919925 16.007436,0.471191375 15.9997739,0 L14.8374111,0 C14.8422353,0.2612305 14.7318449,0.514648437 14.5280909,0.708496125 C13.8246003,1.46386719 13.8246003,2.53808594 14.5280909,3.293457 C14.7309936,3.48706056 14.841384,3.73950194 14.8374111,4 C14.8433704,4.26074219 14.7329801,4.51367188 14.5286585,4.706543 C14.1793253,5.06982425 13.9920305,5.52856444 14.0002601,6 Z"
          />
          <path
            className="svg-block"
            d="M21.0002601,6 L22.1626321,6 C22.1578078,5.73901369 22.2684829,5.48632812 22.4719548,5.29296875 C23.1757347,4.53833006 23.1757347,3.4643555 22.4719548,2.70947262 C22.0596192,2.30371094 22.0596192,1.69824219 22.4719548,1.2924805 C22.8201555,0.928955062 23.007168,0.47094725 22.9997897,0 L21.8374177,0 C21.8419582,0.2612305 21.731567,0.514404312 21.5280951,0.708496125 C20.8245989,1.46386719 20.8245989,2.53808594 21.5280951,3.293457 C21.7309994,3.48706056 21.8413906,3.73950194 21.8374177,4 C21.8436609,4.26098631 21.732702,4.513916 21.5280951,4.706543 C21.1793267,5.07006837 20.9920304,5.52856444 21.0002601,6 Z"
          />
          <path
            className="svg-block"
            d="M7.0002601,6 L8.1626323,6 C8.15667289,5.73925781 8.26706417,5.48632812 8.47138741,5.293457 C9.17545129,4.53857425 9.17545129,3.4643555 8.47138741,2.70947262 C8.05905178,2.30371094 8.05905178,1.69824219 8.47138741,1.2924805 C8.81987204,0.92919925 9.00716834,0.471191375 8.99979004,0 L7.83741785,0 C7.84224213,0.2612305 7.73185084,0.514648437 7.52809513,0.708496125 C6.82459886,1.46386719 6.82459886,2.53808594 7.52809513,3.293457 C7.73099948,3.48706056 7.84139076,3.73950194 7.83741785,4 C7.84337726,4.26074219 7.73298597,4.51367188 7.52866273,4.706543 C7.17932674,5.06982425 6.99203043,5.52856444 7.0002601,6 Z"
          />
        </g>
      </g>
    </svg>
  )
}
