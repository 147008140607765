import { Autocomplete } from '@react-google-maps/api'
import React, { FunctionComponent, useState } from 'react'

interface IMapSearchBoxProps {
  onUserSelectedPlace: (place: any) => void
  bounds: any
}

const MapSearchBox: FunctionComponent<IMapSearchBoxProps> = ({
  onUserSelectedPlace,
  bounds,
}) => {
  const [searchBox, setSearchBox] = useState(null)

  const onLoad = ref => {
    setSearchBox(ref)
  }

  const onPlacesChanged = () =>
    onUserSelectedPlace((searchBox as any).getPlace())

  const handleChange = () => {
    onUserSelectedPlace(null)
  }

  const searchBoxOptions = {
    strictBounds: true,
  }

  return (
    <div>
      <Autocomplete
        onLoad={onLoad}
        onPlaceChanged={onPlacesChanged}
        bounds={bounds} // at this point setting the point only make auto complete bias on the result
        options={searchBoxOptions}
      >
        <input
          type="text"
          placeholder="Search location"
          id="map-search"
          style={{
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            height: `32px`,
            padding: `0 12px`,
            borderRadius: `3px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            outline: `none`,
            textOverflow: `ellipses`,
          }}
          onChange={handleChange}
        />
      </Autocomplete>
    </div>
  )
}

export default MapSearchBox
