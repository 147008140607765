import React from 'react'

export default () => {
  return (
    <svg
      height="24"
      viewBox="0 0 56 60"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="012---Water-Fountain" transform="translate(0 -1)">
        <path
          id="Shape"
          d="m33.547 23.469c-.6365804 1.4928647-.2923887 3.2233514.867 4.359l10.879 10.879c.1874927.1875494.4418052.2929434.707.293h9c.5522847 0 1-.4477153 1-1v-16c0-.5522847-.4477153-1-1-1h-17.757c-1.6231067-.0173779-3.0905876.9629279-3.696 2.469zm20.453 13.531h-7.586l-10.586-10.586c-.5719445-.5721172-.742909-1.4324444-.4331646-2.1797711.3097443-.7473266 1.0391908-1.2344607 1.8481646-1.2342289l16.757-.0000001z"
        />
        <circle id="Oval" cx="41" cy="19" r="1" />
        <circle id="Oval" cx="44" cy="17" r="1" />
        <circle id="Oval" cx="47" cy="16" r="1" />
        <circle id="Oval" cx="50" cy="17" r="1" />
        <path
          id="Shape"
          d="m17.392 8.415-14.036 8.519c-2.07812886 1.2763612-3.3477809 3.5372196-3.356 5.976v33.09c-.00000066 1.3269492.52746984 2.5994771 1.46623342 3.5373028.93876358.9378258 2.21181803 1.4640241 3.53876658 1.4626972.164 0 .328-.009.494-.025 2.59236103-.3283315 4.5273094-2.5470722 4.5-5.16v-27.705l11-6.516v5.446c.0008723 1.028213.3976604 2.0166021 1.108 2.76l6.5 6.789c1.5566146 1.5687648 4.0902351 1.5786145 5.6589999.0219999 1.5687648-1.5566145 1.5786146-4.0902351.0220001-5.6589999l-5.288-5.59v-7.492c-.0072486-1.1722409-.2451004-2.3315985-.7-3.412 2.8467122 1.1901103 6.1369345.3759689 8.1001753-2.0043291 1.9632409-2.3802979 2.1364395-5.76532252.4263967-8.3335683-1.7100429-2.56824578-4.8999873-3.7139524-7.853302-2.8206071-2.9533146.89334529-4.9734146 3.61503323-4.97327 6.7005045 0 .2.014.392.03.586-1.9833457-1.36717475-4.5868927-1.43424412-6.638-.171zm13.608-5.415c2.7614237 0 5 2.23857625 5 5 0 2.7614237-2.2385763 5-5 5s-5-2.2385763-5-5c.0033061-2.76005315 2.2399468-4.99669388 5-5zm-6.827 8.562 1.612 2.373c.7938176 1.1585184 1.2174756 2.5306122 1.215 3.935v7.89c.0033.3018869.1452795.585477.385.769.0491358.0726722.1032664.1418391.162.207l5.306 5.611c.7845205.7787215.7892215 2.0459795.0105 2.8305s-2.0459795.7892215-2.8305.0105l-6.477-6.766c-.3557609-.3722823-.5548194-.8670645-.556-1.382v-7.2c.0002969-.3594836-.1923817-.6914645-.5046664-.8695288-.3122846-.1780643-.696115-.174808-1.0053336.0085288l-13 7.7c-.30369945.1800643-.48993842.5069327-.49.86v28.275c.03441405 1.5885781-1.12618477 2.9512071-2.7 3.17-.84440795.089796-1.68682135-.1865185-2.314-.759-.62710906-.5698835-.98505977-1.3776323-.986-2.225v-33.09c.00623815-1.7424668.91437141-3.3574304 2.4-4.268l14.029-8.517c.9503692-.57871025 2.0930028-.7524654 3.1724387-.4824185 1.0794359.27004691 2.0056484.9613737 2.5715613 1.9194185z"
        />
      </g>
    </svg>
  )
}
